import React, { lazy, Suspense, useEffect, useState } from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBars, faBell, faCog, faTv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Layout } from "antd";
import GlobalStoreContext from "../../GlobalStoreContext";
import Helper from "../../Helper";
import Permission from "../../Permission";
import TeamInfo from "../../teamInfo";
import AcceptInviteModal from "../Team/acceptInviteModal";
import SidebarControlButton from "../UI/SidebarButton";
import InstallPLOTModal from "./installPLOTModal";
import RequestToJoinProject from "./RequestToJoinProject";
const ProjectSettings = lazy(() => import("./ProjectSettings/projectSettings"));

const AcceptAutoJoinToProjectModal = lazy(() => import("./acceptAutoJoinToProjectModal"));
const AcceptProjectInviteModal = lazy(() => import("./acceptProjectInviteModal"));
const LeadTimesPage = lazy(() => import("../../views/leadTimesPage"));
const NotificationsList = lazy(() => import("../Chat/NotificationsList"));
const ProjectCalendar = lazy(() => import("../Calendar/projectCalendar"));
const ProjectLogistics = lazy(() => import("./projectLogistics"));
const ProjectMembers = lazy(() => import("./projectMembers"));
const ProjectPortal = lazy(() => import("./ProjectPortal"));
const PromptJobsiteActiveModal = lazy(() => import("./promptJobsiteActive"));
const PromptForNotificationPreferencesModal = lazy(() => import("../Profile/PromptForNotificationPreferencesModal"));

const { Content } = Layout;

interface ProjectProps {
    objectId?: string;
    eventId?: string;
    page?: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className={Helper.classNames(value !== index ? "invisible" : "tab-panel flex flex-1 h-full")}
        >
            {value === index && (
                <Box sx={{ p: 3 }} className="flex-1">
                    <div className="h-full add-padding flex w-full">{children}</div>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Project: React.FC<ProjectProps> = (props) => {
    const context = React.useContext(GlobalStoreContext);

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [unreadMentions, setUnreadMentions] = useState<number>(0);
    const [anchorElement, setAnchorElement] = useState(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [openShareModal, setOpenShareModal] = useState<boolean>(false);
    const [openJoinModal, setOpenJoinModal] = useState<boolean>(false);
    const [guestToken, setGuestToken] = useState<string | null>(null);
    const [autoApproveToken, setAutoApproveToken] = useState<string | null>(null);
    const [promptedForSetActive, setPromptedForSetActive] = useState<boolean>(false);
    const [activePromptVisible, setActivePromptVisible] = useState<boolean>(false);
    const [isActiveProject, setIsActiveProject] = useState<boolean>(true);
    const [redirectedForLoggedIn, setRedirectedForLoggedIn] = useState<boolean>(false);
    const [installPLOTModalVisible, setInstallPLOTModalVisible] = useState<boolean>(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);
    const [newWeatherAlertEnabled, setNewWeatherAlertEnabled] = useState<any[]>([]);
    const [weatherAlerts, setWeatherAlerts] = useState<any[]>([]);
    const [showProjectProfile, setShowProjectProfile] = useState<boolean>(false);
    const [mtTeam, setMtTeam] = useState<any>(null);
    const [showJoinTeam, setShowJoinTeam] = useState<boolean>(false);
    const [notificationsDrawer, setNotificationsDrawer] = useState<boolean>(false);
    const [projectTabs, setProjectTabs] = useState<any[]>([]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("guest_token")) {
            setGuestToken(urlParams.get("guest_token"));
        }
        if (urlParams.get("auto_approve_token")) {
            setAutoApproveToken(urlParams.get("auto_approve_token"));
        }
        getInvitedTeam();
    }, []);

    useEffect(() => {
        if (context.currentProjectInfo) {
            buildProjectTabs(context.currentProjectInfo);
        }
    }, [context.currentProjectInfo.use_lead_times]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("event")) {
            updateTabAndCookie(projectTabs.findIndex((tab) => tab.label === "CALENDAR"));
        } else if (props.page) {
            switch (props.page.toLowerCase()) {
                case "portal":
                    updateTabAndCookie(projectTabs.findIndex((tab) => tab.label === "PORTAL"));
                    break;
                case "logistics":
                    updateTabAndCookie(projectTabs.findIndex((tab) => tab.label === "LOGISTICS"));
                    break;
                case "events":
                    updateTabAndCookie(projectTabs.findIndex((tab) => tab.label === "CALENDAR"));
                    break;
                case "lead_times":
                    updateTabAndCookie(projectTabs.findIndex((tab) => tab.label === "PROCUREMENT"));
                    break;
                case "directory":
                    updateTabAndCookie(projectTabs.findIndex((tab) => tab.label === "DIRECTORY"));
                    break;
            }
        } else if (Helper.getCookie("last_project_tab")) {
            setActiveIndex(Number(Helper.getCookie("last_project_tab")));
        } else {
            updateTabAndCookie(0);
        }
    }, [projectTabs]);

    const getInvitedTeam = () => {
        if (Helper.getCookie("invited_team") !== "" && Helper.getCookie("invited_team_id") !== "") {
            const team = Helper.getCookie("invited_team");
            const team_id = Helper.getCookie("invited_team_id");
            const teamInfo = { id: parseInt(team_id), team_name: team };

            setMtTeam(teamInfo);
            setShowJoinTeam(true);
        } else {
            if (context.currentViewedTeamInfo) {
                const t = new TeamInfo(context.currentViewedTeamInfo);
                const teamInfo = { id: t.id, team_name: t.team_name };

                setMtTeam(teamInfo);
                setShowJoinTeam(true);
            }
        }
    };

    const updateTabAndCookie = (val) => {
        const tabValue = val > 0 ? val : 0;
        setActiveIndex(val);
        Helper.setCookie("last_project_tab", val);
    };

    const toggleProjectSettingsModal = () => {
        setSettingsModalOpen((prevState) => !prevState);
    };

    const weatherAlertsChange = (alert) => {
        setWeatherAlerts([...weatherAlerts, alert]);
    };

    const changeTab = (newValue: number) => {
        setActiveIndex(newValue);
        Helper.setCookie("last_project_tab", newValue.toString(), 365);
    };

    const buildProjectTabs = async (projectInfo: any = context.currentProjectInfo) => {
        const tempProjectTabs = [
            {
                label: "PORTAL",
                component: <ProjectPortal object_id={props.objectId} />,
            },
            {
                label: "LOGISTICS",
                component: (
                    <ProjectLogistics
                        object_id={props.objectId}
                        guest_token={guestToken}
                        toggleProjectProfile={setShowProjectProfile((prevState) => !prevState)}
                        showProjectProfile={setShowProjectProfile(true)}
                        toggleProjectSettingsModal={toggleProjectSettingsModal}
                    />
                ),
            },
            {
                label: "DIRECTORY",
                component: <ProjectMembers />,
            },
        ];
        if (projectInfo.use_lead_times) {
            tempProjectTabs.splice(2, 0, {
                label: "PROCUREMENT",
                component: (
                    <Suspense
                        fallback={<Skeleton variant="rectangular" width={"100%"} height={"100%"} animation="wave" />}
                    >
                        <LeadTimesPage />
                    </Suspense>
                ),
            });
        }
        if (projectInfo.use_deliveries) {
            tempProjectTabs.splice(2, 0, {
                label: "DELIVERIES",
                component: (
                    <Suspense
                        fallback={<Skeleton variant="rectangular" width={"100%"} height={"100%"} animation="wave" />}
                    >
                        <ProjectCalendar event_id={props.eventId} />
                    </Suspense>
                ),
            });
        }
        return setProjectTabs(tempProjectTabs);
    };

    const toggleDrawer = () => {
        setNotificationsDrawer((prevState) => !prevState);
    };

    return (
        <>
            <Suspense>
                <Content className="wrapper_inner" style={{ margin: "0", height: "100%", borderRadius: "0px" }}>
                    <InstallPLOTModal
                        visible={installPLOTModalVisible}
                        hideModal={() => setInstallPLOTModalVisible(false)}
                    />
                    {context.gottenUserInfo &&
                        showJoinTeam &&
                        context.userHasPermission(Permission.is_invited, mtTeam?.id, null) && (
                            <AcceptInviteModal
                                team={mtTeam}
                                teamId={mtTeam?.id}
                                teamName={mtTeam?.team_name}
                            ></AcceptInviteModal>
                        )}
                    {autoApproveToken && (
                        <Suspense
                            fallback={
                                <Skeleton variant="rectangular" width={"100%"} height={"100%"} animation="wave" />
                            }
                        >
                            <AcceptAutoJoinToProjectModal auto_approve_token={autoApproveToken} />
                        </Suspense>
                    )}
                    {!context.isMobile ? (
                        <div
                            className={Helper.classNames(
                                "flex flex-row justify-between mr-8",
                                context.isMobile && "mt-[calc(48px+1rem)]"
                            )}
                        >
                            <div className="truncate page-title">
                                <IconButton
                                    size="small"
                                    onClick={() => context.setCollapseSidebar(!context.collapseSidebar)}
                                >
                                    <FontAwesomeIcon icon={faBars} color="#ff9e00" />
                                </IconButton>
                                <div className="project-title">
                                    <h1>{context.currentProjectInfo.project_name}</h1>
                                </div>
                            </div>
                            <div className="flex flex-row items-center basis-1/3 gap-4 justify-end">
                                {context.userHasPermission(
                                    Permission.is_owner,
                                    context.currentTeam?.id,
                                    context.currentProjectInfo.id
                                ) && (
                                    <>
                                        <Tooltip title="Edit jobsite settings, information, and integrations">
                                            <IconButton
                                                aria-label="project settings"
                                                onClick={() => toggleProjectSettingsModal()}
                                            >
                                                <FontAwesomeIcon icon={faCog} />
                                            </IconButton>
                                        </Tooltip>

                                        <ProjectSettings
                                            settingsModalOpen={settingsModalOpen}
                                            toggleProjectSettingsModal={toggleProjectSettingsModal}
                                            weatherAlertsChange={weatherAlertsChange}
                                            reloadProjectTabs={buildProjectTabs}
                                        />
                                    </>
                                )}

                                {context.userHasPermission(
                                    Permission.is_member,
                                    context.currentTeam?.id,
                                    context.currentProjectInfo.id
                                ) && (
                                    <div>
                                        <button
                                            className="plot-button secondary-button"
                                            aria-label="Notifications"
                                            onClick={toggleDrawer}
                                        >
                                            <FontAwesomeIcon
                                                icon={faBell}
                                                className={Helper.classNames(!context.isMobile && "mr-2")}
                                            />
                                            {!context.isMobile && "Notifications"}
                                        </button>

                                        <NotificationsList
                                            notificationsDrawer={notificationsDrawer}
                                            toggleDrawer={toggleDrawer}
                                        />
                                    </div>
                                )}

                                {context.gottenUserInfo && (
                                    <Suspense
                                        fallback={
                                            <Skeleton
                                                variant="rectangular"
                                                width={"100%"}
                                                height={"100%"}
                                                animation="wave"
                                            />
                                        }
                                    >
                                        <AcceptProjectInviteModal />
                                    </Suspense>
                                )}

                                {context.userHasPermission(
                                    Permission.is_member,
                                    context.currentTeam?.id,
                                    context.currentProjectInfo.id
                                ) && (
                                    <>
                                        <Tooltip title="Launch fullscreen jobsite command center">
                                            <div>
                                                <button
                                                    className="plot-button primary-button flex flex-row items-center"
                                                    onClick={() => {
                                                        window.location.href = `/projects/${context.currentProjectToken}/dashboard`;
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTv as IconProp} className="mr-2" />
                                                    Command Center
                                                </button>
                                            </div>
                                        </Tooltip>
                                    </>
                                )}

                                <Suspense
                                    fallback={
                                        <Skeleton
                                            variant="rectangular"
                                            width={"100%"}
                                            height={"100%"}
                                            animation="wave"
                                        />
                                    }
                                >
                                    <PromptJobsiteActiveModal
                                        visible={activePromptVisible}
                                        closeModal={() => {
                                            setActivePromptVisible(false);
                                        }}
                                    />
                                </Suspense>
                                {context.userInfo && (
                                    <Suspense
                                        fallback={
                                            <Skeleton
                                                variant="rectangular"
                                                width={"100%"}
                                                height={"100%"}
                                                animation="wave"
                                            />
                                        }
                                    >
                                        <PromptForNotificationPreferencesModal userInfo={context.userInfo} />
                                    </Suspense>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="menu-title">
                            <div className="page-title flex flex-row items-center justify-between mx-4">
                                <SidebarControlButton />

                                <h1 className="!ml-6">{context.currentProjectInfo.project_name}</h1>
                                {!context.userHasPermission(
                                    Permission.is_member,
                                    context.currentTeam?.id,
                                    context.currentProjectInfo.id
                                ) && <RequestToJoinProject />}
                            </div>
                        </div>
                    )}

                    {/* Project tabs set using projectTabs() which sets the state of projectTabs if features are enabled/disabled for the project */}

                    {activeIndex !== null && (
                        <Tabs
                            className={Helper.classNames(context.windowSize.width < 992 && "mt-[82px]")}
                            value={activeIndex}
                            onChange={(e, val) => changeTab(val)}
                            variant={context.windowSize.width < 992 ? "scrollable" : "standard"}
                            allowScrollButtonsMobile
                            textColor="primary"
                            indicatorColor="primary"
                            style={{ fontWeight: 500, fontSize: "small" }}
                            aria-label="scrollable auto tabs example"
                        >
                            {projectTabs.map((tab, index) => (
                                <Tab key={index} label={tab.label} {...a11yProps(index)} />
                            ))}
                        </Tabs>
                    )}

                    {projectTabs.map((tab, index) => (
                        <TabPanel value={activeIndex} index={index} key={index} className="tab-panel">
                            {tab.component}
                        </TabPanel>
                    ))}
                </Content>
            </Suspense>
        </>
    );
};

export default Project;
