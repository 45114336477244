import { Divider } from "@mui/material";
import React from "react";
import GlobalStoreContext from "../../GlobalStoreContext";
import Helper from "../../Helper";
import Modal from "../UI/Modal";

interface Props {
    visible: boolean;
    hideModal(): void;
}

class InstallPLOTModal extends React.Component<Props> {
    static contextType = GlobalStoreContext;
    context!: React.ContextType<typeof GlobalStoreContext>;

    formRef = React.createRef<any>();

    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        return (
            <>
                <Modal
                    title="Installation Options"
                    open={this.props.visible}
                    onCancel={this.props.hideModal}
                    footer={null}
                >
                    <div>
                        <div className="mb-4">
                            <Divider textAlign="center" className="text-left">
                                <div className="font-[Oswald] font-[500] text-lg">INSTALL ON WINDOWS OR MAC</div>
                            </Divider>
                        </div>
                        <div
                            className={Helper.classNames(
                                "flex items-center",
                                this.context.windowSize.width < 992 ? "px-4" : "px-6"
                            )}
                        >
                            <div>Use the install button in your address bar to install our Desktop App</div>
                            <div className="flex justify-center h-28 ml-2">
                                <img src="/PWA_Chrome_Edge.png" className="object-cover" />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="my-4">
                            <Divider textAlign="center" className="text-left">
                                <div className="font-[Oswald] font-[500] text-lg">INSTALL ON IOS OR ANDROID</div>
                            </Divider>
                        </div>
                        <div className="flex">
                            <div className="flex justify-center h-12 w-1/2">
                                <a
                                    href="https://apps.apple.com/us/app/plot-jobsite-management/id6444870566"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                        className="object-cover"
                                    />
                                </a>
                            </div>
                            <div className="flex justify-center h-12 w-1/2">
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.getplotmobile"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src="https://lh3.googleusercontent.com/RyLoNcOmb91IxHIP9NWfC82chbsCsT-5R25efns1FmuM8xz6znE4CRjIEBosZ1FH2xG1UqH6Axyp-vPFnm4sazbrsaB-S0QT_cN9uWU9UKoSQYCjYQ=s0"
                                        className="object-cover"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="my-4">
                            <Divider textAlign="center" className="text-left">
                                <div className="font-[Oswald] font-[500] text-lg">INSTALL ON FIRETV</div>
                            </Divider>
                        </div>
                        <div className="flex justify-center h-12">
                            <a
                                target="_blank"
                                href="https://www.amazon.com/Plot-Communications-INC-Jobsite-Management/dp/B0BYB4HVY4/"
                                rel="noreferrer"
                            >
                                <img
                                    src="https://images-na.ssl-images-amazon.com/images/G/01/mobile-apps/devportal2/res/images/amazon-appstore-badge-english-black.png"
                                    className="object-cover"
                                />
                            </a>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default InstallPLOTModal;
