import "@mui/material/styles/styled";
import { LoginCallback } from "@okta/okta-react";
import { Layout } from "antd";
import Sider from "antd/lib/layout/Sider";
// import { isSupported } from "firebase/messaging";
import React, { lazy, useContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, useParams } from "react-router-dom";
import ProcoreIntegrationSuccess from "../src/components/Integrations/Procore/ProcoreIntegrationSuccess";
import PromptOnLink from "../src/components/MagicLinks/promptOnLink";
import EulaAcceptModal from "../src/components/UI/EulaAcceptModal";
// import { onMessageListener, requestFirebaseNotificationPermission } from "../src/firebaseInit";
import GlobalStoreContext from "../src/GlobalStoreContext";
import PageContentType from "../src/PageContentType";
import PlotAPI from "../src/plotAPI";
import "./App.css";
import GlobalTheme from "./globalTheme";
import ProjectView from "./ProjectView";

const DashboardPage = lazy(() => import("../src/views/DashboardPage"));
const DeliveryEvent = lazy(() => import("../src/components/DeliveryEvent/deliveryEvent"));
const FireTVConnect = lazy(() => import("../src/components/FireTVConnect/fireTVConnect"));
const Sidebar = lazy(() => import("../src/components/Sidebar/sidebar"));
const TeamPage = lazy(() => import("../src/views/teamPage"));

const App: React.FC<any> = () => {
    const [mobile, setMobile] = useState(false);
    const [showEulaModal, setShowEulaModal] = useState(false);
    const globalStoreContext = useContext(GlobalStoreContext);

    // TODO: https://getplot.atlassian.net/browse/WEBAPP-2609
    // useEffect(() => {
    //     // Check if the user is on an iOS device. Right now Firebase's SDK is
    //     // bitching about the user being on an iOS device and crashing the app.
    //     const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    //     if (isIOS) {
    //         return;
    //     }

    //     const android = /Android/.test(navigator.userAgent);
    //     if (android) {
    //         return;
    //     }

    //     if (!isSupported()) {
    //         return;
    //     }

    //     if (
    //         window.location.hostname.split(".")[2] == "com" ||
    //         window.location.hostname.split(".")[2] == "xyz" ||
    //         window.location.hostname.split(".")[2] == "dev"
    //     ) {
    //         requestFirebaseNotificationPermission()
    //             .then((firebasePermission) => {
    //                 // eslint-disable-next-line no-console
    //                 //console.log(firebaseToken);

    //                 if (firebasePermission) {
    //                     PlotAPI.getCurrentUserInfo().then((response) => {
    //                         if (response) {
    //                             if (!response.receive_web_notifications) {
    //                                 PlotAPI.putCurrentUserInfo({ receive_web_notifications: true });
    //                             }
    //                         }
    //                     });
    //                 }
    //             })
    //             .catch(() => {});
    //         onMessageListener();
    //     }
    // }, []);

    useEffect(() => {
        PlotAPI.getCurrentUserInfo().then((response) => {
            if (response) {
                if (!response.accepted_eula) {
                    // Return if the current url ends in `/dashboard` or `/code`
                    if (window.location.href.endsWith("/dashboard") || window.location.href.endsWith("/code")) {
                        return;
                    }

                    // Show a modal making them click a button to accept the EULA, link to the EULA
                    // Create state this is "showEulaModal" and set it to true
                    setShowEulaModal(true);
                }
            }
        });
    }, []);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("mobile") && mobile == false) {
        if (urlParams.get("mobile") == "true") {
            setMobile(true);
        }
    }

    return (
        <>
            <GlobalTheme>
                <Layout hasSider={true}>
                    <PromptOnLink />

                    {
                        // TODO: Do we really want mobile to have sidebar but not desktop for this?

                        !window.location.href.endsWith("/dashboard") && !window.location.href.endsWith("/code") && (
                            <Sider
                                trigger={null}
                                collapsible
                                collapsed={globalStoreContext.collapseSidebar}
                                onCollapse={(e) => {
                                    globalStoreContext.setCollapseSidebar(e);
                                }}
                                onBreakpoint={(e) => {
                                    globalStoreContext.setCollapseSidebar(e);
                                }}
                                breakpoint="lg"
                                collapsedWidth={0}
                            >
                                <Sidebar />
                            </Sider>
                        )
                    }
                    {
                        // NOTE: If you add or remove routes from this file you
                        // should also update the `routes.rb` file to match so
                        // that the Rails server will render the React app for
                        // those routes. */
                    }
                    <Layout>
                        <Router>
                            <Routes>
                                <Route path="/" element={<TeamView />} />
                                <Route path="/code" element={<FireTVConnectView />} />
                                <Route path="/profile" element={<TeamView page={"profile"} />} />
                                <Route path="/members" element={<TeamView page={"members"} />} />
                                <Route path="/events" element={<TeamView page={"events"} />} />
                                <Route path="/projects/:unique_token" element={<ProjectView />} />
                                <Route
                                    path="/projects/:unique_token/portal"
                                    element={<ProjectView page={"portal"} />}
                                />

                                <Route
                                    path="/projects/:unique_token/logistics"
                                    element={<ProjectView page={"logistics"} />}
                                />
                                <Route
                                    path="/projects/:unique_token/logistics/map/:object_id"
                                    element={<ProjectView page={"logistics"} />}
                                />
                                <Route
                                    path="/projects/:unique_token/events"
                                    element={<ProjectView page={"events"} />}
                                />
                                <Route
                                    path="/projects/:unique_token/events/:event_id"
                                    element={<ProjectView page={"events"} />}
                                />
                                <Route
                                    path="/projects/:unique_token/directory"
                                    element={<ProjectView page={"directory"} />}
                                />

                                <Route
                                    path="/projects/:unique_token/lead_times"
                                    element={<ProjectView page={"lead_times"} />}
                                />

                                <Route path="/event/:unique_token" element={<DeliveryEventView />} />

                                <Route path="/projects/:unique_token/dashboard" element={<DashboardView />} />
                                <Route path="/login/callback" element={<LoginCallback />} />

                                <Route path="/integrations/procore/success" element={<ProcoreIntegrationSuccess />} />
                                <Route path="/integrations/autodesk-build/success" element={<></>} />
                                <Route path="/integrations/autodesk-build/failure" element={<></>} />
                            </Routes>
                        </Router>
                    </Layout>
                </Layout>
            </GlobalTheme>
            <EulaAcceptModal showEulaModal={showEulaModal} setShowEulaModal={setShowEulaModal} />
        </>
    );
};

function FireTVConnectView() {
    return (
        <>
            <FireTVConnect />
        </>
    );
}

function TeamView(props: { page?: string }) {
    const globalStoreContext = useContext(GlobalStoreContext);

    useEffect(() => {
        globalStoreContext.setPageContentType(PageContentType.TEAM);
    }, []);

    return (
        <>
            <TeamPage page={props.page} />
        </>
    );
}

function DeliveryEventView() {
    const { unique_token } = useParams();

    const globalStoreContext = useContext(GlobalStoreContext);

    useEffect(() => {
        globalStoreContext.setPageContentType(PageContentType.DELIVERY);
    }, []);

    return (
        <>
            <DeliveryEvent unique_token={unique_token} />
        </>
    );
}

function DashboardView() {
    const { unique_token } = useParams();

    const globalStoreContext = useContext(GlobalStoreContext);

    useEffect(() => {
        globalStoreContext.setPageContentType(PageContentType.PROJECT);
        globalStoreContext.setCurrentProjectToken(unique_token);
    }, []);

    return (
        <>
            <DashboardPage unique_token={unique_token} />
        </>
    );
}

export default App;
