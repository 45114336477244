import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Project from "../src/components/Project/project";
import GlobalStoreContext from "../src/GlobalStoreContext";
import PageContentType from "../src/PageContentType";

interface ProjectViewProps {
    page?: string;
}

const ProjectView: React.FC<ProjectViewProps> = ({ page }) => {
    const { unique_token } = useParams();
    const globalStoreContext = useContext(GlobalStoreContext);

    useEffect(() => {
        globalStoreContext.setPageContentType(PageContentType.PROJECT);
        if (unique_token) {
            globalStoreContext.setCurrentProjectToken(unique_token);
        }
    }, []);

    return <>{globalStoreContext.currentProjectInfo && <Project page={page} />}</>;
};

export default ProjectView;
