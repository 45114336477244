import React from "react";
import { createRoot } from "react-dom/client";
import { usePromiseTracker } from "react-promise-tracker";
import Spinner from "../src/components/Spinner/spinner";
import ErrorBoundary from "../src/ErrorBoundary";

import * as Sentry from "@sentry/react";
import GlobalStoreProvider from "../src/GlobalStoreProvider";
import App from "./App";
import "./index.css";

let sentryEnvironmentName = "unknown";
if (window.location.hostname.endsWith("getplot.dev")) sentryEnvironmentName = "development";
if (window.location.hostname.endsWith("getplot.xyz")) sentryEnvironmentName = "staging";
if (window.location.hostname.endsWith("getplot.com")) sentryEnvironmentName = "production";

Sentry.init({
    dsn: "https://264e590a6094eed79c36ef9e6d3a8f11@o4507664126443520.ingest.us.sentry.io/4507664203710464",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 0.01,
    tracePropagationTargets: ["getplot.dev", "getplot.xyz", "getplot.com"],
    release: `plot_api_next_react@${process.env.PLOT_VERSION}`,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.1,
    environment: sentryEnvironmentName,
});

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();

    return promiseInProgress && <Spinner></Spinner>;
};

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
});

const root = document.getElementById("root");

if (root) {
    createRoot(root).render(
        <React.StrictMode>
            <Sentry.ErrorBoundary fallback={<ErrorBoundary></ErrorBoundary>} showDialog={false}>
                <LoadingIndicator />
                <GlobalStoreProvider>
                    <App />
                </GlobalStoreProvider>
            </Sentry.ErrorBoundary>
        </React.StrictMode>
    );
}
