import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import React, { useContext } from "react";
import GlobalStoreContext from "../../GlobalStoreContext";

const SidebarControlButton = () => {
    const context = useContext(GlobalStoreContext);

    return (
        <IconButton size="small" onClick={() => context.setCollapseSidebar(!context.collapseSidebar)}>
            {/* If you change the icon in the future you can use flip to control the horizontal direction
            in Fontawesome.
            flip={context.collapseSidebar && "horizontal"} */}
            <FontAwesomeIcon icon={faBars} color="#ff9e00" />
        </IconButton>
    );
};

export default SidebarControlButton;
